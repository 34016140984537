<template>
  <div>
    <v-card>
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">CHEQUE BOOK MONITORING (OVERALL)</h4>
        </v-toolbar-title>
      </v-toolbar>
      <v-layout row wrap class="align-center mx-2 mt-5">
        <v-layout col>
          <v-flex xs12 md3>
            <v-form ref="form2" class="multi-col-validation">
              <v-select
                v-model="view_by"
                class="mx-2  mt-2"
                dense
                outlined
                label="View By"
                :items="['Bank Code','Particulars']"
                @change="selected_view"
                :rules="rules.combobox_rule"
              ></v-select>
              <v-select v-if="view_by==='Bank Code'"
                        v-model="bank_id"
                        class="mx-2"
                        dense
                        outlined
                        label="Bank"
                        :items="bank_items"
                        item-value="id"
                        item-text="bank_code"
                        @change="selected_bank"
                        :rules="rules.combobox_rule"
              ></v-select>
              <v-select v-if="view_by==='Particulars' && !alert"
                        v-model="particulars"
                        class="mx-2"
                        dense
                        outlined
                        label="Particulars"
                        :items="['All Existing','Existing Used']"
                        :rules="rules.combobox_rule"
                        @change="selected_particulars"
              ></v-select>
              <v-text-field v-if="alert"
                            v-model="particulars"
                            class="mx-2"
                            dense
                            outlined
                            label="Particulars"
                            readonly
              ></v-text-field>
              <v-col cols="12" v-show="alert">
                <v-alert
                  color="warning"
                  text
                  class="mb-0"

                >
                  <div class="d-flex align-start">
                    <v-icon color="warning">
                      {{ icons.mdiAlertOutline }}
                    </v-icon>

                    <div class="ms-3">
                      <p class="text-base font-weight-medium mb-1">
                        {{alert_message}}
                      </p>
                    </div>
                  </div>
                </v-alert>
              </v-col>
            </v-form>
            <v-data-table dense v-if="view_by==='Bank Code'"
                          :headers="headers2"
                          :items="vouchered_items"
            >
              <template v-slot:item="{ item }">
                <tr @click="activerow(item)"
                    :class="{'info white--text': item.id===selectedDepositId}">
                  <td>
                    {{ item.from_cheque_no+' - '+item.to_cheque_no }}
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-flex>
          <v-flex xs12 md9 class="mx-2" v-if="data_items.length>0">
            <v-data-table dense
                          hide-default-footer
                          disable-pagination
                          :headers="headers"
                          :items="data_items"
            >
              <template v-slot:item="{ item }">
                <tr>
                  <td>
                    {{ item.bank_code }}
                  </td>
                  <td>
                    {{ item.cheque_no }}
                  </td>
                  <td>
                    {{ item.status }}
                  </td>
                  <td>
                    {{ item.voucher_no }}
                  </td>
                  <td>
                    {{ item.cheque_date }}
                  </td>
                  <td>
                    {{ item.cheque_good_date }}
                  </td>
                  <td>
                    {{ item.payee }}
                  </td>
                  <td>
                    {{ item.particulars }}
                  </td>
                  <td>
                    {{ item.amount }}
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-flex>
        </v-layout>
      </v-layout>
    </v-card>
    <snack-bar-dialog
      :snackbar_flag="this.snackbar"
      :color="this.snackbar_color"
      :snackbar_text="this.snackbar_text"
    />
  </div>
</template>

<script>
  import {mdiAccountOutline, mdiAccountCashOutline, mdiInformationOutline} from "@mdi/js";
  import moment from "moment";
  import {mapActions, mapGetters} from "vuex";
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'
  import category from "@/store/module/data/category";

  const initialState = () => {
    return {
      alert: false,
      alert_message: '',
      headers: [
        {text: 'Bank Code', value: 'date_of_deposit', sortable: false},
        {text: 'Cheque #', value: 'tin', sortable: false},
        {text: 'Status', value: 'tin', sortable: false},
        {text: 'Voucher #', value: 'tin', sortable: false},
        {text: 'Cheque Date', value: 'tin', sortable: false},
        {text: 'Cheque Good Date', value: 'tin', sortable: false},
        {text: 'Payee', value: 'tin', sortable: false},
        {text: 'Particulars', value: 'tin', sortable: false},
        {text: 'Amount', value: 'tin', sortable: false},
      ],
      headers2: [
        {text: 'Cheque Series', value: 'date_of_deposit', sortable: false},
      ],
      data_items: [],
      vouchered_items: [],
      bank_items: [],
      selectedDepositId: -1,
      view_by: '',
      bank_id: '',
      particulars: '',
    }
  }
  export default {
    components: {
      snackBarDialog,
    },
    setup() {
      return {
        icons: {
          mdiAccountOutline,
          mdiAccountCashOutline,
          mdiInformationOutline,
        },
      }
    },
    data() {
      return initialState()
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('authentication', ['employee_id', 'month_of']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text', 'company_logo']),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('cheque_book_series_pads', ['list_of_cheque_book_pads', 'check_cheque_pads_status', 'list_of_cheque_series_pads']),
      activerow(value) {
        this.data_items = []
        this.selectedDepositId = value.id;
        const data = new FormData()
        data.append('bank_id', this.bank_id);
        data.append('start', value.from_cheque_no);
        data.append('end', value.to_cheque_no);
        this.check_cheque_pads_status(data)
          .then(response => {
            this.data_items = response.data
          })
          .catch(error => {
            console.log(error)
          })
      },
      selected_view(value) {
        this.vouchered_items = []
        this.data_items = []
        this.bank_items = []
        if (value === 'Bank Code') {
          this.list_of_cheque_book_pads()
            .then(response => {
              this.bank_items = response.data
            })
            .catch(error => {
              console.log(error)
            })
        }
      },
      selected_bank(value) {
        this.data_items = []
        var index = this.bank_items.map(function (x) {
          return x.id;
        }).indexOf(value)
        if (index != -1) {
          this.vouchered_items = this.bank_items[index].cheque_book_series_pads
        }
      },
      selected_particulars(value) {
        this.data_items = []
        this.alert = true
        this.alert_message = 'PLEASE WAIT....'
        this.list_of_cheque_series_pads({
          particulars: value
        })
          .then(response => {
            this.data_items = response.data
            this.alert = false
          })
          .catch(error => {
            console.log(error)
          })
      },
    }
  }
</script>
